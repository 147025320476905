@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap');

html {
  font-family: 'Rubik' !important;
  margin: 0px !important;
  padding: 0px !important;
}

body {
  margin: 0;
  font-family: 'Rubik' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: 'Roboto',Montserrat,Helvetica,Arial,serif;
}

.trendingnowtext {
  background: radial-gradient(
    circle at 100%,
    rgb(0, 68, 255), /* Lightest shade */
    rgba(61, 113, 255, 0.3), /* Lighter shade */
    rgba(39, 97, 255, 0.5), /* Light shade */
    rgba(93, 134, 249, 0.7), /* Medium shade */
    rgba(93, 134, 249, 0.9), /* Darker shade */
    rgb(93, 134, 249)        /* Original color */
  );
  font-weight: 600;
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  -webkit-background-clip: text; /* For cross-browser support */
  -webkit-text-fill-color: transparent;
  animation: animatedTextGradient 1.5s linear infinite;
}


@keyframes animatedTextGradient {
  to {
    background-position: 200% center;
  }
}




.ele{
  animation: 2s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.deployMessage{
  animation: 2s fadeOut;
  animation-fill-mode: forwards;
  visibility: hidden;
}


@keyframes fadeOut {
  99% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}


@keyframes drawThick {
  0% {
    stroke-dashoffset: 180;
 }
  100% {
    stroke-dashoffset: 0;
 }
}
@keyframes drawDots {
  0% {
    stroke-dashoffset: 60;
 }
  100% {
    stroke-dashoffset: 0;
 }
}
@keyframes drawStatic {
  0% {
    stroke-dashoffset: 8;
 }
  30% {
    stroke-dashoffset: 0;
 }
  100% {
    stroke-dashoffset: 8;
 }
}
@keyframes rumble {
  0% {
    transform: translate(-1px, -1px);
 }
  10% {
    transform: translate(0, 0);
 }
  15% {
    transform: translate(-1px, -1px);
 }
  35% {
    transform: translate(1px, 1px);
 }
  45% {
    transform: translate(-1px, -1px);
 }
  55% {
    transform: translate(1px, 1px);
 }
  65% {
    transform: translate(0, 0);
 }
  80% {
    transform: translate(1px, 1px);
 }
  100% {
    transform: translate(-1px, -1px);
 }
}
@keyframes rotate3d {
  0% {
    transform: rotate3d(1, -1, 0, 0);
 }
  30% {
    transform: rotate3d(1, -1, 0, 0deg);
 }
  50% {
    transform: rotate3d(1, -1, 0, 60deg);
 }
  100% {
    transform: rotate3d(1, -1, 0, 0);
 }
}
@keyframes launch {
  5% {
    transform: translate(0, 0);
 }
  8% {
    transform: translate(2px, -2px);
 }
  30% {
    transform: translate(-60px, 60px);
 }
  100% {
    transform: translate(0, 0);
 }
}
@keyframes slideIn {
  0% {
    transform: translate(-60px, 60px);
 }
  35% {
    transform: translate(-60px, 60px);
 }
  100% {
    transform: translate(0, 0);
 }
}
.loadingWrap {
  text-align: center;
}
.loading {
  max-width: 70px;
  width: 100%;
}
.loading .background {
  fill: #102555;
}
.loading .rotate {
  animation: rotate3d 4 1;
  transform-origin: center;
}
.loading .rumble {
  animation: rumble 1s infinite 0.6666666667s;
}
.loading .arrow {
  animation: launch 2s 1;
  fill: #fff;
}
.loading .exhaust {
  animation: slideIn 2s 1;
}
.loading .gradient stop {
  stop-color: #fff;
  stop-opacity: 0.2;
}
.loading .gradient stop:last-child {
  stop-opacity: 0;
}
.loading .gradientBox {
  fill: url('#gradient');
}
.loading .filteredGroup {
  filter: url('#stickyFilter');
}
.loading .line {
  animation-iteration-count: infinite;
  stroke: #dde1ee;
  stroke-linecap: round;
}
.loading .lineThick {
  animation-name: drawThick;
  animation-timing-function: linear;
}
.loading .lineDots {
  animation-name: drawDots;
  animation-timing-function: linear;
  stroke-dasharray: 0, 30;
  stroke-width: 5.5;
}
.loading .lineStatic {
  animation-name: drawStatic;
  animation-timing-function: ease;
  stroke-dasharray: 22;
  stroke-width: 8;
}
.loading .line1 {
  animation-duration: 1.6s;
  stroke-dasharray: 8, 22, 3, 27, 16, 14;
  stroke-width: 4;
}
.loading .line2 {
  animation-duration: 1.3s;
  stroke-dasharray: 18, 12, 10, 20, 3, 27;
  stroke-width: 6;
}
.loading .line3 {
  animation-duration: 1.4s;
  stroke-dasharray: 3, 27, 10, 20, 20, 10;
  stroke-width: 4;
}
.loading .line4 {
  animation-duration: 0.4s;
}
.loading .line5 {
  animation-duration: 0.7s;
}
.loading .line6 {
  animation-duration: 0.8s;
}
.loading .line7 {
  animation-duration: 0.6s;
}
.label {
  color: #555;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  margin-top: 1rem;
}
/* Website Link */
.website-link {
  background: #f8faff;
  border-radius: 50px 0 0 50px;
  bottom: 30px;
  color: #324b77;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  height: 34px;
  filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, .1));
  padding: 0 20px 0 40px;
  position: fixed;
  right: 0;
  text-align: left;
  text-decoration: none;
}
.website-link__icon {
  left: -10px;
  position: absolute;
  top: -12px;
  width: 44px;
}
.website-link__name {
  display: block;
  font-size: 14px;
  line-height: 14px;
  margin: 5px 0 3px;
}
.website-link__last-name {
  color: #55bada;
}
.website-link__message {
  color: #8aa8c5;
  display: block;
  font-size: 12px;
  line-height: 7px;
}

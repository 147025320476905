.main {
    font-family: "Open Sans";
    width: 1170px;
    margin: 0 auto;
    font-family: 'Rubik', serif;
}

.price-table {
    width: 100%;
    border-collapse: collapse;
    border: 0 none;
}

.price-table tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.price-table tr td {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 24px;
    font-size: 14px;
}

.price-table tr td:first-child {
    border-left: 0 none;
}

.price-table tr td:not(:first-child) {
    text-align: center;
}

/* .price-table tr:nth-child(even) {
    background-color: #FFFFFF;
} */

/* .price-table tr:hover {
    background-color: #EEEEEE;
} */

.price-table .fa-check {
    fill: #5d86f9;
}

.price-table .fa-times {
    fill: #D8D6E3;
}

/* Highlighted column */
.price-table tr:nth-child(2n) td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.25);
}

.price-table tr td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.15);
    padding: 8px 48px;
}

.price-table tr td:nth-child(3) .fa-check,
.price-table tr:nth-child(2n) td:nth-child(3) .fa-check {}


.price-table tr.price-table-head td {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Rubik', serif;
    text-transform: uppercase;
}

/* .price-table tr.price-table-head {
    background-color: #f5f5f5;
    color: #111;
} */

.price-table td.price {
    /* color: #544251; */
    padding: 16px 24px;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Rubik', serif;
}

.price-table td.price a {
    background-color: #5d86f9;
    color: #FFFFFF;
    padding: 12px 32px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Rubik', serif;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 64px;
}

.price-table td.price-table-popular {
    font-family: 'Rubik', serif;
    border-top: 3px solid #5d86f9;
    color: rgba(0, 0, 0, 0.65);
    text-transform: uppercase;
    font-size: 12px;
    padding: 12px 48px;
    font-weight: 700;
}

.price-table .price-blank {
    background-color: #fafafa;
    border: 0 none;
}

.price-table svg {
    width: 90px;
    fill: #5d86f9;
}

.animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #ff1361 67%,
            #fff800 100%);
    /* background-image: linear-gradient(-225deg,
    #dcb979 0%,
    #e7c78a 10%,
    #ffe7c1 20%,
            #f2d8a5 39%,
            #dcb979 67%,
            #b8904a 100%); */

    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 15px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}
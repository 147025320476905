@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap);
.main {
    font-family: "Open Sans";
    width: 1170px;
    margin: 0 auto;
    font-family: 'Rubik', serif;
}

.price-table {
    width: 100%;
    border-collapse: collapse;
    border: 0 none;
}

.price-table tr:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.price-table tr td {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 8px 24px;
    font-size: 14px;
}

.price-table tr td:first-child {
    border-left: 0 none;
}

.price-table tr td:not(:first-child) {
    text-align: center;
}

/* .price-table tr:nth-child(even) {
    background-color: #FFFFFF;
} */

/* .price-table tr:hover {
    background-color: #EEEEEE;
} */

.price-table .fa-check {
    fill: #5d86f9;
}

.price-table .fa-times {
    fill: #D8D6E3;
}

/* Highlighted column */
.price-table tr:nth-child(2n) td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.25);
}

.price-table tr td:nth-child(3) {
    background-color: rgba(216, 214, 227, 0.15);
    padding: 8px 48px;
}

.price-table tr td:nth-child(3) .fa-check,
.price-table tr:nth-child(2n) td:nth-child(3) .fa-check {}


.price-table tr.price-table-head td {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Rubik', serif;
    text-transform: uppercase;
}

/* .price-table tr.price-table-head {
    background-color: #f5f5f5;
    color: #111;
} */

.price-table td.price {
    /* color: #544251; */
    padding: 16px 24px;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Rubik', serif;
}

.price-table td.price a {
    background-color: #5d86f9;
    color: #FFFFFF;
    padding: 12px 32px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Rubik', serif;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 64px;
}

.price-table td.price-table-popular {
    font-family: 'Rubik', serif;
    border-top: 3px solid #5d86f9;
    color: rgba(0, 0, 0, 0.65);
    text-transform: uppercase;
    font-size: 12px;
    padding: 12px 48px;
    font-weight: 700;
}

.price-table .price-blank {
    background-color: #fafafa;
    border: 0 none;
}

.price-table svg {
    width: 90px;
    fill: #5d86f9;
}

.animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #ff1361 67%,
            #fff800 100%);
    /* background-image: linear-gradient(-225deg,
    #dcb979 0%,
    #e7c78a 10%,
    #ffe7c1 20%,
            #f2d8a5 39%,
            #dcb979 67%,
            #b8904a 100%); */

    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 15px;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}
.react-tel-input .form-control:focus{
    border-color: #160044;
    box-shadow: none;
}

.react-tel-input .form-control{
    width: 100%;
    background: transparent;
}

#wrapper{
    width: 100%;
    max-width: 725px;
    margin: 0px auto;
}
.gears{
  width:0;
  display: inline-block;
}
.gears:first-child{
  margin-right: 100px;
}
.gears-container{
    width:150px; 
    height: 150px;
    font-size:24px;
    padding: 9%;
    position: relative; 
    margin: 0px auto;
}

.gear-rotate{
    width: 2em;
    height: 2em;
  top: 50%; 
  left: 50%; 
  margin-top: -1em;
  margin-left: -1em;
    background: #939496;
    position: absolute;
    border-radius: 1em;
    animation: 1s gear-rotate linear infinite;
}
.gear-rotate-left{
  margin-top: -2.2em;
  top: 50%;
  width: 2em;
    height: 2em;
    background: #939496;
    position: absolute;
    border-radius: 1em;
  animation: 1s gear-rotate-left linear infinite;
}

.gear-rotate::before, .gear-rotate-left::before {
    width: 2.8em;
    height: 2.8em;
    background: 
      linear-gradient(0deg,transparent 39%,#939496 39%,#939496 61%, transparent 61%),
    linear-gradient(60deg,transparent 42%,#939496 42%,#939496 58%, transparent 58%),
    linear-gradient(120deg,transparent 42%,#939496 42%,#939496 58%, transparent 58%);
    position: absolute;
    content:"";
    top: -.4em;
    left: -.4em;
    border-radius:1.4em;
}
.gear-rotate::after, .gear-rotate-left::after {
    width: 1em;
    height: 1em;
    background: #ffffff;
    position: absolute;
    content:"";
    top: .5em;
    left: .5em;
    border-radius: .5em;
}

/*
 * Keyframe Animations 
 */

@keyframes gear-rotate {
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(-180deg); 
  }
}

@keyframes gear-rotate-left {
 0% { 
   -webkit-transform: rotate(30deg); 
  }
  100% { 
    -webkit-transform: rotate(210deg);
  }
}
.ele{
  animation: 2s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.deployMessage{
  animation: 2s fadeOut;
  animation-fill-mode: forwards;
  visibility: hidden;
}


@keyframes fadeOut {
  99% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}


@keyframes drawThick {
  0% {
    stroke-dashoffset: 180;
 }
  100% {
    stroke-dashoffset: 0;
 }
}
@keyframes drawDots {
  0% {
    stroke-dashoffset: 60;
 }
  100% {
    stroke-dashoffset: 0;
 }
}
@keyframes drawStatic {
  0% {
    stroke-dashoffset: 8;
 }
  30% {
    stroke-dashoffset: 0;
 }
  100% {
    stroke-dashoffset: 8;
 }
}
@keyframes rumble {
  0% {
    transform: translate(-1px, -1px);
 }
  10% {
    transform: translate(0, 0);
 }
  15% {
    transform: translate(-1px, -1px);
 }
  35% {
    transform: translate(1px, 1px);
 }
  45% {
    transform: translate(-1px, -1px);
 }
  55% {
    transform: translate(1px, 1px);
 }
  65% {
    transform: translate(0, 0);
 }
  80% {
    transform: translate(1px, 1px);
 }
  100% {
    transform: translate(-1px, -1px);
 }
}
@keyframes rotate3d {
  0% {
    transform: rotate3d(1, -1, 0, 0);
 }
  30% {
    transform: rotate3d(1, -1, 0, 0deg);
 }
  50% {
    transform: rotate3d(1, -1, 0, 60deg);
 }
  100% {
    transform: rotate3d(1, -1, 0, 0);
 }
}
@keyframes launch {
  5% {
    transform: translate(0, 0);
 }
  8% {
    transform: translate(2px, -2px);
 }
  30% {
    transform: translate(-60px, 60px);
 }
  100% {
    transform: translate(0, 0);
 }
}
@keyframes slideIn {
  0% {
    transform: translate(-60px, 60px);
 }
  35% {
    transform: translate(-60px, 60px);
 }
  100% {
    transform: translate(0, 0);
 }
}
.loadingWrap {
  text-align: center;
}
.loading {
  max-width: 70px;
  width: 100%;
}
.loading .background {
  fill: #102555;
}
.loading .rotate {
  animation: rotate3d 4 1;
  transform-origin: center;
}
.loading .rumble {
  animation: rumble 1s infinite 0.6666666667s;
}
.loading .arrow {
  animation: launch 2s 1;
  fill: #fff;
}
.loading .exhaust {
  animation: slideIn 2s 1;
}
.loading .gradient stop {
  stop-color: #fff;
  stop-opacity: 0.2;
}
.loading .gradient stop:last-child {
  stop-opacity: 0;
}
.loading .gradientBox {
  fill: url('#gradient');
}
.loading .filteredGroup {
  filter: url('#stickyFilter');
}
.loading .line {
  animation-iteration-count: infinite;
  stroke: #dde1ee;
  stroke-linecap: round;
}
.loading .lineThick {
  animation-name: drawThick;
  animation-timing-function: linear;
}
.loading .lineDots {
  animation-name: drawDots;
  animation-timing-function: linear;
  stroke-dasharray: 0, 30;
  stroke-width: 5.5;
}
.loading .lineStatic {
  animation-name: drawStatic;
  animation-timing-function: ease;
  stroke-dasharray: 22;
  stroke-width: 8;
}
.loading .line1 {
  animation-duration: 1.6s;
  stroke-dasharray: 8, 22, 3, 27, 16, 14;
  stroke-width: 4;
}
.loading .line2 {
  animation-duration: 1.3s;
  stroke-dasharray: 18, 12, 10, 20, 3, 27;
  stroke-width: 6;
}
.loading .line3 {
  animation-duration: 1.4s;
  stroke-dasharray: 3, 27, 10, 20, 20, 10;
  stroke-width: 4;
}
.loading .line4 {
  animation-duration: 0.4s;
}
.loading .line5 {
  animation-duration: 0.7s;
}
.loading .line6 {
  animation-duration: 0.8s;
}
.loading .line7 {
  animation-duration: 0.6s;
}
.label {
  color: #555;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  margin-top: 1rem;
}
/* Website Link */
.website-link {
  background: #f8faff;
  border-radius: 50px 0 0 50px;
  bottom: 30px;
  color: #324b77;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  height: 34px;
  filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, .1));
  padding: 0 20px 0 40px;
  position: fixed;
  right: 0;
  text-align: left;
  text-decoration: none;
}
.website-link__icon {
  left: -10px;
  position: absolute;
  top: -12px;
  width: 44px;
}
.website-link__name {
  display: block;
  font-size: 14px;
  line-height: 14px;
  margin: 5px 0 3px;
}
.website-link__last-name {
  color: #55bada;
}
.website-link__message {
  color: #8aa8c5;
  display: block;
  font-size: 12px;
  line-height: 7px;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .checkmark {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 0% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
  }
.react-tel-input .form-control:focus{
    border-color: #160044;
    box-shadow: none;
}

.react-tel-input .form-control{
    width: 100%;
    background: transparent;
}

.forCompleteProfile input{
    border-radius: 25px;
    height: 48px;
}
html {
  font-family: 'Rubik' !important;
  margin: 0px !important;
  padding: 0px !important;
}

body {
  margin: 0;
  font-family: 'Rubik' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: 'Roboto',Montserrat,Helvetica,Arial,serif;
}

.trendingnowtext {
  background: radial-gradient(
    circle at 100%,
    rgb(0, 68, 255), /* Lightest shade */
    rgba(61, 113, 255, 0.3), /* Lighter shade */
    rgba(39, 97, 255, 0.5), /* Light shade */
    rgba(93, 134, 249, 0.7), /* Medium shade */
    rgba(93, 134, 249, 0.9), /* Darker shade */
    rgb(93, 134, 249)        /* Original color */
  );
  font-weight: 600;
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  -webkit-background-clip: text; /* For cross-browser support */
  -webkit-text-fill-color: transparent;
  animation: animatedTextGradient 1.5s linear infinite;
}


@keyframes animatedTextGradient {
  to {
    background-position: 200% center;
  }
}





.react-tel-input .form-control:focus{
    border-color: #160044;
    box-shadow: none;
}

.react-tel-input .form-control{
    width: 100%;
    background: transparent;
}

.forCompleteProfile input{
    border-radius: 25px;
    height: 48px;
}